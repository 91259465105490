import React, { useState, useEffect } from "react"

import { Form, Row } from "react-bootstrap"
import dateFormat from 'dateformat';

import InputField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/input'
import SelectField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/select'
import TextAreaField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/textarea'
import CheckboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/checkbox'
import RadioboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/radio'
import ButtonField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/button'
import HtmlBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/html'

import { phoneNumberValidation, getUrlParams } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis"

import { PageLinks } from "../../../common/page-links";


function Tenancy_Details(props) {


  //console.log("location ==>", location, replace_slashes, props.title);

//console.log("adverseCredit ==>", props.adverseCredit)
let adverse_details = props.adverseCredit;

  var name_title = ["Mr", "Mrs", "Miss", "Ms", "Sir", "Dr", "Lady", "Lord"];
  var adverse_credit = ["None", "CCJs", "Bankruptcy", "IVA"];

  var fields = ([
    {
        text: 'Full Name*',
        element: "html",
        class: "mt-3 content_r-m form-label"
    },
    {
        grpmd: "4",
        label: "",
        name: `tenant_salutation_${props.tenancy_count}`,
        element: "select",
        placeholder: "Title*",
        required: true,
        values: name_title,
        divclass: "form-wrap",
        labelClass: "annual-txt",
        class: "full_wdth_box",
        option_with_label:false
    },
    {
      grpmd: "8",
      label: "",
      placeholder: "Name*",
      name: `tenant_name_${props.tenancy_count}`,
      type: "text",
      element: "input",
      required: true,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
        grpmd: "6",
        label: "Email",
        placeholder: "",
        name: `email_${props.tenancy_count}`,
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap",
    },
    {
        grpmd: "6",
        label: "Telephone",
        placeholder: "",
        name: `tenant_phone_${props.tenancy_count}`,
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap",
    },
    {
        grpmd: "12",
        label: "Current Address",
        placeholder: "",
        name: `tenant_address_${props.tenancy_count}`,
        element: "textarea",
        class: "",
        required: true,
        rows: "4",
        labelClass: "annual-txt",
        errorMsg: "Enter the property address.",
        divclass: "form-wrap",
    },
    {
        grpmd: "12",
        label: "From",
        placeholder: "",
        name: `tenant_from_${props.tenancy_count}`,
        type: "date",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap",
    },
    {
        grpmd: "12",
        label: "Are you able to provide a valid proof of ID and proof of current address: ",
        placeholder: "",
        name: `tenant_proof_${props.tenancy_count}`,
        element: "radio",
        required: true,
        selected:"",
        values: ['Yes', 'No'],
        labelClass: "annual-txt",
        class: "",
        divclass: "radio_wrap"
    },
    {
        text: `<b>Current Employment Information</b>`,
        element: "html",
        class: "content_r-m mb-4 heading_text mt-4"
    },
    {
        grpmd: "12",
        label: "Employment Status",
        placeholder: "",
        name: `tenant_employment_status_${props.tenancy_count}`,
        element: "radio",
        required: true,
        selected:"",
        values: ['Full Time', 'Part Time', 'Self Employed', 'Student', 'Temporary/Fixed Term Contract', 'Other'],
        labelClass: "annual-txt",
        class: "",
        divclass: "radio_wrap form-wrap full_wdth_check mb-0"
    }, 
    {
        grpmd: "12",
        label: "Employment Other Details",
        placeholder: "",
        name: `tenant_emp_other_${props.tenancy_count}`,
        type: "text",
        element: "input",
        required: false,
        labelClass: "annual-txt",
        divclass: "mt-2 form-wrap",
    },
    {
        grpmd: "6",
        label: "Employment Start Date",
        placeholder: "",
        name: `tenant_emp_start_date_${props.tenancy_count}`,
        type: "date",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        class:"date_field form-control",
        divclass: "form-wrap"
    },
    {
        grpmd: "6",
        label: "Job Title",
        placeholder: "",
        name: `tenant_job_title_${props.tenancy_count}`,
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap"
    },
    {
        grpmd: "6",
        label: "Basic Salary (£)",
        placeholder: "",
        name: `tenant_salary_${props.tenancy_count}`,
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap"
    },
    {
        grpmd: "6",
        label: "Total Income (£)",
        placeholder: "",
        name: `tenant_total_income_${props.tenancy_count}`,
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap"
    },
    // {
    //     grpmd: "12",
    //     label: "Adverse Credit History",
    //     placeholder: "",
    //     name: `tenant_adverse_credit_${props.tenancy_count}`,
    //     element: "radio",
    //     required: true,
    //     selected:"",
    //     values: ['Yes', 'No'],
    //     labelClass: "annual-txt",
    //     class: "",
    //     divclass: "radio_wrap form-wrap"
    // }, 
    // {
    //     grpmd: "12",
    //     label: "Do you have any adverse credit? You need to declare whether you have any CCJs, Bankruptcy or IVAs at all?",
    //     name: `tenant_adverse_credit_${props.tenancy_count}`,
    //     placeholder: "None",
    //     element: "select",
    //     required: true,
    //     values: adverse_credit,
    //     divclass: "form-wrap",
    //     labelClass: "annual-txt",
    //     class: "full_wdth_box"
    // },
    {
        grpmd: "12",
        label: "Do you have any adverse credit? You need to declare whether you have any CCJs, Bankruptcy or IVAs at all?",
        placeholder: "",
        name: `tenant_adverse_credit_${props.tenancy_count}`,
        element: "radio",
        required: true,
        selected:"",
        values: adverse_credit,
        labelClass: "annual-txt",
        class: "",
        divclass: "form-wrap radio_wrap"
    }
])

let adverse_details_show = adverse_details.filter(item => item.field_name === `tenant_adverse_credit_${props.tenancy_count}` && item.value !== "None")
//console.log("adverse_details", adverse_details_show.length)
fields.push(
    {
        grpmd: "12",
        label: "",
        placeholder: "",
        name: `adverse_credit_details_${props.tenancy_count}`,
        type: "text",
        element: "input",
        required: adverse_details_show.length > 0 ? true : false,
        labelClass: "annual-txt",
        divclass: `mt-2 form-wrap ${adverse_details_show.length} ${adverse_details_show.length ? "" : "d-none"}`,
    },
)

fields.push(
    {
        grpmd: "6",
        label: "DOB",
        placeholder: "",
        name: `tenant_dob_${props.tenancy_count}`,
        type: "date",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        class:"date_field form-control",
        divclass: "form-wrap"
    },
    {
        grpmd: "6",
        label: "Nationality",
        placeholder: "",
        name: `tenant_nationality_${props.tenancy_count}`,
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        divclass: "form-wrap"
    },
)

  //console.log("selectedOptions", selectedOptions);

  return (
    <div className="form rent_form">

        <Row className="custom-text-field-wrapper no_direction">

            {fields.map((field, index) => {
            if ("input" === field.element) {
                return (
                <InputField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    type={field.type}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    labelClass={field.labelClass}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    pattern={field.patternchk}
                    handlechange={props.handlechange}
                    errorMsg={field.errorMsg}
                    defaultValue={field?.defaultValue}
                    min={field?.min}
                    max={field?.max}
                />
                );
            }
            if ("select" === field.element) {
                return (
                <SelectField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    required={field.required}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    labelClass={field.labelClass}
                    values={field.values}
                    key={`${field.element}~${index}`}
                    handlechange={props.handlechange}
                    componentprops={props}
                    errorMsg={field.errorMsg}
                    //selectedValue={selectedOptions}
                //isError= {Object.keys(selectError).length > 0 ? (selectError[field.name] ? '' : true) : ''}
                    optionWithLabel={false}
                />
                );
            }
            if ("textarea" === field.element) {
                return (
                <>

                    <TextAreaField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={props.handlechange}
                    errorMsg={field.errorMsg}
                    />
                </>
                );
            }
            if ("checkbox" === field.element) {
                return (
                <CheckboxField
                    name={field.name}
                    divclass={field.divclass}
                    ref={field.ref}
                    value={field.value}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.name}~${index}`}
                    handlechange={props.handlechange}
                />
                );
            }
            if ("radio" === field.element) {
                return (
                <RadioboxField
                    name={field.name}
                    divclass={field.divclass}
                    ref={field.ref}
                    values={field.values}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.name}~${index}`}
                    handlechange={props.handlechange}
                    label={field.label}
                />
                );
            }
            if ("html" === field.element) {
                return (
                <HtmlBox
                    text={field.text}
                    fieldClass={field.class}
                    key={`${field.element}~${index}`}
                />
                );
            }
            // if ("captcha" === field.element) {
            //   return (
            //     <ReCaptchaBox
            //       fieldClass={field.class}
            //       captRef={field.captchaRef}
            //       key={`${field.element}~${index}`}
            //       handleonVerify={handleonVerify}
            //     />
            //   );
            // }
            if ("button" === field.element) {
                return (
                <>

                    <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                    grpmd={field.grpmd}
                    customClass={field.customClass}
                    />

                </>
                );
            }
            })
            }
        </Row>

    </div>
  );
}


const TenancyDetails = (props) => (
  <Tenancy_Details {...props} />

)

export default TenancyDetails