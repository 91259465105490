import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import dateFormat from 'dateformat';

import { Form, Row } from "react-bootstrap"

import InputField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/input'
import SelectField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/select'
import TextAreaField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/textarea'
import CheckboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/checkbox'
import RadioboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/radio'
import ButtonField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/button'
import HtmlBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/html'
import ReCaptchaBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/recaptcha'
import { postFormData, api_form_error } from "gatsby-theme-starberry-lomondgroup/src/components/forms/api/Api"
import { FormsSubject } from "gatsby-theme-starberry-lomondgroup/src/common/form-mail-subjects"
import { PageLinks } from "../../common/page-links";
import { phoneNumberValidation, Tenant_Offer_Mail_Function_Linley, getUrlParams, ScrollToDiv } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis"
import TenancyDetails from './TenancyDetails/tenancy_details'
import { getUser } from "@starberry/myaccount-website-utils"

import 'gatsby-theme-starberry-lomondgroup/src/components/forms/assets/styles/_index.scss'


function Tenancy_Offer(props) {

  const location = useLocation();
  const userData = getUser();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrormsg, setShowerrorMsg] = useState("");
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [show_keep_pet, setKeepPet] = useState(false);
  const [adverseCredit, setAdverseCredit] = useState([]);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const [selectedOptions, setSelectedOptions] = useState({ value: 1, label: 1, subscription_value: "", type: "select", name: "number_of_tenants" });
  const [selectError, setSelectError] = useState(false);

  const [showTenantDiv, setShowTenant] = useState("tenant_div_1");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  var to_email_id = location?.state?.to_email_id ? location?.state?.to_email_id : props.to_email_id ? props.to_email_id : "";

  var replace_slashes = location.pathname.replace(/\//g, '');

  let num_tenants = [{label:1, value:1}];
  for(let num = 2; num <= 5; num++){
    num_tenants.push({label:num, value:num});
  }


  //console.log("location ==>", location, replace_slashes, props.title);


  var fields = ([
    {
      element: "config",
      formname: props.title ? props.title : "Tenancy Offer",
      form_type: props.title ? props.title : "Tenancy Offer",
      error_text: "Highlighted fields are required.",
      success_text: "Thank you, a member of our team will be in contact soon.",
      email_temp_user: "tenant_offer_user",
      email_temp_admin: "tenant_offer_admin",
      email_server_func: "tenancy-offer",
      event_tracking: "tenancy-offer",
      page_url: location.href,
      email_subject_user: FormsSubject.tenancy_offer.user_subject,
      email_subject_admin: FormsSubject.tenancy_offer.admin_subject
    },
  ])

  fields.push(
    {
      grpmd: "12",
      label: "Property Address",
      placeholder: "",
      name: "property_address",
      type: "text",
      element: "input",
      required: true,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Agency name viewed property through?",
      placeholder: "",
      name: "agency_name",
      type: "text",
      element: "input",
      required: true,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      text: `<b>Tenancy information</b>`,
      element: "html",
      class: "content_r-m mb-4 heading_text"
    },
    {
      grpmd: "12",
      label: "Proposed Start Date:",
      placeholder: "",
      name: `proposed_start_date`,
      type: "date",
      element: "input",
      required: true,
      labelClass: "annual-txt",
      class:"date_field form-control",
      divclass: "form-wrap"
    },
    {
      grpmd: "12",
      label: "Proposed Lease Length:",
      placeholder: "",
      name: "proposed_lease_length",
      type: "text",
      element: "input",
      required: true,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Proposed Rental Amount (£):",
      placeholder: "",
      name: "proposed_rental_amount",
      type: "text",
      element: "input",
      required: true,
      labelClass: "annual-txt",
      divclass: "form-wrap",
    },
    {
      grpmd: "12",
      label: "Payment Frequency:",
      placeholder: "",
      name: "payment_frequency",
      element: "radio",
      required: true,
      selected:"",
      values: ['Monthly', '6 Months', 'Full term upfront'],
      labelClass: "annual-txt",
      class: "",
      divclass: "form-wrap radio_wrap"
    },
    {
      grpmd: "12",
      label: "Will there be any Pets at the Property",
      placeholder: "",
      name: "have_pets",
      element: "radio",
      required: true,
      selected:"",
      values: ['No', 'Yes'],
      labelClass: "annual-txt",
      class: "",
      divclass: "radio_wrap"
    },
    {
      grpmd: "12",
      label: "Which pets do you wish to keep at the property?",
      placeholder: "",
      name: "pets_details",
      type: "text",
      element: "input",
      required: show_keep_pet ? true : false,
      labelClass: "annual-txt",
      divclass: `mt-2 form-wrap ${show_keep_pet ? "" : "d-none"}`,
    },
    {
      grpmd: "12",
      label: "Any other Tenancy requests:(If have children will need: Names, DOB, Nationality, Pet breed etc)",
      placeholder: "",
      name: "tenancy_requests",
      element: "textarea",
      class: "",
      required: false,
      rows: "4",
      labelClass: "annual-txt",
      divclass: `mt-3 form-wrap`,
    },
    {
      grpmd: "12",
      label: "Number of Tenants",
      name: "number_of_tenants",
      placeholder: "1",
      element: "select",
      required: false,
      values: num_tenants,
      divclass: "form-wrap",
      labelClass: "annual-txt",
      class: "full_wdth_box"
    }
  )

  fields.push(
    {
      grpmd: "12",
      name: "SUBMIT",
      type: "submit",
      element: "button",
      value: "Submit",
      class: "btn btn-green",
      labelClass: "",
      customClass: "custom-button-wrapper mt-4",
      divclass: "form-wrap"
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef
    },
  );

  const handlechange = event => {
    // remove initial empty spaces

    if (event.type === "select") {
      //event.value = event.value.trimStart()
      if(event.name === "number_of_tenants"){
        setSelectedOptions(event);
      }

    } else {

      if(event.target.name === "have_pets"){
        if(event.target.value === "Yes"){
            setKeepPet(true)
        } else{
            setKeepPet(false)
        }
      }
      if(event.target.name.indexOf("tenant_adverse_credit") > -1){
        //console.log("adverse_credit", event.target.name, event.target.value)
        let filter_adverse = adverseCredit.filter(item => item.field_name !== event.target.name)
        let add_adverse = {"field_name": event.target.name, "value":event.target.value}

        setAdverseCredit([...filter_adverse, add_adverse])
      }

      if (event.target.name === "telephone") {
        var valid_phone = phoneNumberValidation(event.target.value);
        if (valid_phone) {
          event.target.value = valid_phone;
        } else {
          event.target.value = '';
        }
      } else {
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  useEffect(() => {

    if (token !== '') {

      const processFromData = async () => {

        let formData = new FormData();

        formData.append('data', JSON.stringify(formvalues));
        setShowerror(false);

        postFormData(formData).then(async apiRes => {

          //console.log("apiRes", apiRes);

          window.grecaptcha.reset();

          if (apiRes?.success === true) {
            // tracking event
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'formSubmit',
              'formType': 'form-' + fields[0].event_tracking,
              'formId': 'form-' + fields[0].event_tracking,
              'formName': fields[0].formname,
              'formLabel': fields[0].formname
            });


            setThankyou(true);
            setTimeout(() => {
              setThankyou(false)
            }, 6000);

            await Tenant_Offer_Mail_Function_Linley(formvalues);
            
          } else {
            setShowerror(true);
            setThankyou(false);
            setShowerrorMsg(api_form_error)
          }

          setToken("");

        });

        formvalues['g-recaptcha-response'] = token;

        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }


      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      setShowerrorMsg(fields[0].error_text)

      if (selectedOptions?.value) {
        setSelectError(false);
      } else {
        setSelectError(true);
      }

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else {

      event.preventDefault();
      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => {
        //console.log("formsdata ==> 2", key, formsdata[key].name, formsdata[key].value)

        if(formsdata[key].name){
          if (formsdata[key].type === "checkbox") {
            if (formsdata[key].checked === true) {
              json[formsdata[key].name] = 'yes'
            }
          } else if (formsdata[key].type === "radio") {
            if (formsdata[key].checked === true) {
              json[formsdata[key].name] = formsdata[key].value
            }
          } else {
            json[formsdata[key].name] = formsdata[key].value
          }
        }

      })

      // var date_time = dateFormat(startDate, "mmm dd, yyyy") + " " + dateFormat(startTime, "hh:MM TT");
      // //json['name'] = json.first_name+' '+json.last_name;
      // var params = getUrlParams();

      // console.log("json ==>", json);

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;

      json['name'] = json.tenant_name_1;
      json['email'] = json.email_1;
      json['have_pets'] = json.pets_details ? json.pets_details : json.have_pets;
      json['number_of_tenants'] = parseInt(json.number_of_tenants) > 1 ? parseInt(json.number_of_tenants) : 1;

      setFormvalues(json);
      // setToken("test");

      if(process.env.GATSBY_CAPTCHA_MODE === "test"){
        setToken("test");
      } else{
        recaptchaRef.current.execute();
      }

      setValidated(false);
      setSelectError(false);
      setSelectedOptions({});

      // reset form
      const form = event.target
      form.reset();
      setStartDate();
      setStartTime();

    }
  };

  const showTenantDet = (disp_div) => {
    if(disp_div !== showTenantDiv){
      setShowTenant(disp_div)
      setTimeout(() => {
        ScrollToDiv(disp_div)
      }, 500)
    } else{
      setShowTenant("")
    }


  }

  const tenant_det = [];

  
  let inc_val = selectedOptions?.value > 1 ? selectedOptions?.value : 1;
  for(let i=1; i <= inc_val; i++){
    tenant_det.push(
      <>
        <div className="col-md-12"><div className="tenant_heading" onClick={() => showTenantDet(`tenant_div_${i}`)}>Tenant {i} Details: <i className={`icon ${showTenantDiv === `tenant_div_${i}` ? "icon-minus" : "icon-plus"}`}></i></div></div>
        <div className={`${showTenantDiv === `tenant_div_${i}` ? "d-block" : "d-none"} tenant_div_${i}`} id={`tenant_div_${i}`}>
          <TenancyDetails tenancy_count={i} handlechange={handlechange} adverseCredit={adverseCredit} />
        </div>
      </>
    )
  }

  let test_obj = { test_1: "testing", test_2: "testing2"}
  let inc = 2
 
  //console.log("selectedOptions test_1", test_obj["test_"+inc]);

  return (
    <div className="form">

      {showerror && <div className="alert alert-danger">
        {showerrormsg}
      </div>}
      <div ref={myRef}></div>

      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}


      <Form name={fields[0].formname} className={props.classNames} action="/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">

        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={fields[0].formname} />
        <input type="hidden" name="to_email_id" value={to_email_id} />
        <input type="hidden" name="admin_email" value={"Yes"} />

        <input type="hidden" name="bot-field" />

        <Row className="custom-text-field-wrapper no_direction">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={field.type === 'valuation_date' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date' ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  //selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  isError={selectError}
                //isError= {Object.keys(selectError).length > 0 ? (selectError[field.name] ? '' : true) : ''}
                  optionWithLabel={true}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>
                  <TextAreaField
                    name={field.name}
                    divclass={field.divclass}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    errorMsg={field.errorMsg}
                  />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
          })
          }
          
          {tenant_det}

          {fields.map((field, index) => {
            if ("button" === field.element) {
              return (
              <>

                  <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  grpmd={field.grpmd}
                  customClass={field.customClass}
                  />

              </>
              );
            }
          })}
        </Row>
        
      </Form>


    </div>
  );
}


const TenancyOffer = (props) => (
  <Tenancy_Offer {...props} />

)

export default TenancyOffer